import React from 'react';
import {Routes, Route, HashRouter} from "react-router-dom";
import {
    MainPage,
    PaymentFormTestingPage,
    ThreeDSActionHandlingFormTestingPage,
} from "./components/pages";

function App() {
    return (
        <HashRouter>
            <Routes>
                <Route path="/" element={<MainPage />}>
                    <Route path="/payment-form" element={<PaymentFormTestingPage />}/>
                    <Route path="/threeds-form" element={<ThreeDSActionHandlingFormTestingPage />}/>
                </Route>
            </Routes>
        </HashRouter>
    )
}

export default App;
