import React, {useEffect} from "react";

const PaymentFormTestingPage: React.FC = () => {
    useEffect(() => {
        window.location.href = "payment.form.sdk.html";
    }, []);

    return null;
}

export default PaymentFormTestingPage;
