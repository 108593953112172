import React, {useEffect} from "react";

const ThreeDSActionHandlingFormTestingPage: React.FC = () => {
    useEffect(() => {
        window.location.href = "threeds.form.sdk.html"
    })

    return null;
}

export default ThreeDSActionHandlingFormTestingPage;
