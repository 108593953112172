import React from "react";
import {Link, Outlet} from "react-router-dom";

const MainPage: React.FC = () => {
    return(
        <>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <a className="navbar-brand" href="/">SDK Testing tools</a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <Link className='nav-link' id='paymentFormSdk' to="/payment-form">Payment Form</Link>
                        </li>
                        <li className="nav-item">
                            <Link className='nav-link' id='threeDSSdk' to="/threeds-form">Three DS Form</Link>
                        </li>
                    </ul>
                </div>
            </nav>

            <Outlet/>
        </>
    )
}

export default MainPage;
